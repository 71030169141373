import '../fonts/Lato-Black.woff2';
import '../fonts/Lato-BlackItalic.woff2';
import '../fonts/Lato-Bold.woff2';
import '../fonts/Lato-BoldItalic.woff2';
import '../fonts/Lato-Hairline.woff2';
import '../fonts/Lato-HairlineItalic.woff2';
import '../fonts/Lato-Italic.woff2';
import '../fonts/Lato-Light.woff2';
import '../fonts/Lato-LightItalic.woff2';
import '../fonts/Lato-Regular.woff2';
import '../fonts/Merriweather-Black.woff2';
import '../fonts/Merriweather-BlackItalic.woff2';
import '../fonts/Merriweather-Bold.woff2';
import '../fonts/Merriweather-BoldItalic.woff2';
import '../fonts/Merriweather-Italic.woff2';
import '../fonts/Merriweather-Light.woff2';
import '../fonts/Merriweather-LightItalic.woff2';
import '../fonts/Merriweather-Regular.woff2';

import '../sass/main.scss';

import './common/focus.js';
import './common/css-variables.js';
import './common/iframes.js';
import './common/blocks.js';
import './common/iframe-parent-links.js';

import './layouts/cookies-notice.js';
import './layouts/site-header.js';
import './layouts/site-menu-bar.js';
import './layouts/site-menu-modal.js';

import './common/on-screen.js';

import { initModals } from './common/modals.js';

(() => {
    initModals();
})();
