import axios from 'axios';

(() => {
    const $searchForm = document.querySelector('.site-header-main__search-form');
    const $searchFormInput = document.querySelector('.site-header-main__search-form-input');
    const $searchFormResults = document.querySelector('.site-header-main__search-form-results');

    const typeAheadController = new AbortController();

    let searchResults = [];

    const preventPageScroll = e => {
        if (e.keyCode !== 38 && e.keyCode !== 40) return;
        
        e.preventDefault();
    };

    const setSearchResults = results => {
        if (!results) {
            $searchFormResults.classList.remove('site-header-main__search-form-results--show');
            $searchFormResults.innerHTML = '';

            return;
        }

        $searchFormResults.innerHTML = results.map(item => `<li><button type="button">${item}</button></li>`).join('');
        $searchFormResults.classList.add('site-header-main__search-form-results--show');

        const $searchFormResultsButtons = $searchFormResults.querySelectorAll('button');

        $searchFormResultsButtons.forEach(($button, index) => {
            $button.addEventListener('click', () => {
                $searchFormInput.value = $button.textContent;

                $searchForm.submit();
            });

            $button.addEventListener('keydown', e => {
                if (e.keyCode === 38 && $searchFormResultsButtons[index - 1]) {
                    $searchFormResultsButtons[index - 1].focus();
                }

                if (e.keyCode === 38 && !$searchFormResultsButtons[index - 1]) {
                    $searchFormInput.focus();
                }

                if (e.keyCode === 40 && $searchFormResultsButtons[index + 1]) {
                    $searchFormResultsButtons[index + 1].focus();
                }
            });

            $button.addEventListener('focus', e => {
                document.addEventListener('keydown', preventPageScroll, {once: true});
            });
        });
    };

    const search = e => {
        if (!e.target.value) {
            setSearchResults(null);

            return;
        }

        if (searchResults[e.target.value]) {
            setSearchResults(searchResults[e.target.value]);

            return;
        }

        axios.get('https://www.tattersalls.com/4DCGI/TypeAhead', {
            params: {
                option: 'QuickFind',
                letters: e.target.value,
                rndval: new Date().getTime(),
            },
            signal: typeAheadController.signal,
        }).then(response => {
            let items = response.data.split(/[0-9]+###/gi);
            
            items = items.filter(item => item);
            items = items.map(item => item.replace('|', '').trim());

            searchResults[e.target.value] = items;

            setSearchResults(items);
        });
    }

    const initSearchForm = () => {
        if (!$searchFormInput || !$searchFormResults) return;

        $searchFormInput.addEventListener('keydown', e => {
            if (e.keyCode === 40 && e.target.selectionStart === e.target.value.length) {
                const $searchFormResultsButton = $searchFormResults.querySelector('button');

                if ($searchFormResultsButton) {
                    $searchFormResults.classList.add('site-header-main__search-form-results--show');

                    $searchFormResultsButton.focus();
                }

                document.addEventListener('keydown', preventPageScroll, {once: true});

                return;
            }
        });

        $searchFormInput.addEventListener('keyup', e => search(e));
        
        $searchFormInput.addEventListener('focus', e => search(e));
    };

    window.addEventListener('click', e => {
        if (e.target !== $searchFormInput && $searchFormResults?.classList?.contains('site-header-main__search-form-results--show')) {
            $searchFormResults.classList.remove('site-header-main__search-form-results--show');
        }
    });

    initSearchForm();
})();
